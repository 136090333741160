<template>
  <div class="hold-transition">
    <loading v-if="cargando" />
    <div class="wrapper">
      <div class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-6">
                <h5 class="mb-0">Faltantes</h5>
              </div>
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li class="breadcrumb-item active">Hidrocarburos</li>
                  <li class="breadcrumb-item active">Faltantes</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section class="content">
          <div class="container-fluid">
            <div class="card" style="display: inherit">
              <div class="card-header pt-2 pb-2">
                <div class="row">
                  <div class="col-md-3 ml-auto d-flex justify-content-end">
                    <div class="btn-group mr-2">
                      <button
                        type="button"
                        data-toggle="modal"
                        data-target="#modal-form-round-trip"
                        style="
                         cursor: pointer;
                          background: linear-gradient(to right, #1e3a8a, #3b82f6);
                          color: white;
                          border: none;
                          padding: 6px 13px;
                          border-radius: 5px;
                          "
                        v-if="
                          $store.getters.can(
                            'hidrocarburos.faltantes.roundTrip'
                          )
                        "
                        @click="modalRoundTrip()"
                      >
                        <i class="fas fa-receipt"></i><br />Asignar Round Trip y Loteo
                      </button>
                    </div>
                    <div class="btn-group">
                      <button
                        type="button"
                        style="
                        cursor: pointer;
                        background: linear-gradient(to right, #059669, #10b981);
                        color: white;
                        border: none;
                        padding: 6px 13px;
                        border-radius: 5px;
                        "
                        data-toggle="modal"
                        data-target="#modal-form-faltantes-export"
                      >
                        <i class="fas fa-download"></i><br />Exportar
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div class="card-body">
                  <div class="col-md-12 col-sm-12 col-xs-12 pt-2">
                    <div class="card card-info card-outline collapsed-card">
                      <div class="card-header pt-2 pb-2">
                        <h3 class="card-title">
                          <i class="fas fa-filter"></i>
                          Filtros
                        </h3>
                        <div class="card-tools">
                          <button
                            type="button"
                            data-card-widget="collapse"
                            data-toggle="tooltip"
                            title="Collapse"
                            class="btn btn-tool"
                          >
                            <i class="fas fa-plus"></i>
                          </button>
                        </div>
                      </div>
                      <div class="card-body">
                        <div class="row">
                          <div class="form-group col-md-2">
                            <label>N° Diferencia Guia</label>
                            <input
                              type="number"
                              v-model="filtros.id"
                              placeholder="N° viaje"
                              label="id"
                              class="form-control form-control-sm"
                              @keyup.enter="getIndex()"
                            />
                          </div>
                          <div class="form-group col-md-3">
                            <label>N° Guia</label>
                            <input
                              type="number"
                              v-model="filtros.guia_id"
                              placeholder="N° guia"
                              label="guia_id"
                              class="form-control form-control-sm"
                              @keyup.enter="getIndex()"
                            />
                          </div>
                          <div class="form-group col-md-2">
                            <label>Vehículo</label>
                            <input
                              type="text"
                              class="form-control form-control-sm"
                              placeholder="Placa"
                              v-model="filtros.vehiculo_id"
                            />
                          </div>
                          <div class="form-group col-md-2">
                            <label>Remolque</label>
                            <input
                              type="text"
                              class="form-control form-control-sm"
                              placeholder="Placa"
                              v-model="filtros.remolque_id"
                            />
                          </div>
                          <div class="form-group col-md-3">
                            <label>Conductor</label>
                            <v-select
                              :class="
                                $store.getters.getDarkMode ? 'dark-vselect' : ''
                              "
                              v-model="conductor"
                              placeholder="Nombre"
                              label="nombre"
                              :options="listasForms.conductores"
                              @input="seleccionarConductor()"
                            ></v-select>
                          </div>

                          <!-- <div class="form-group col-md-2">
                      <label>Producto</label>
                      <v-select
                        :class="
                          $store.getters.getDarkMode ? 'dark-vselect' : ''
                        "
                        v-model="producto"
                        placeholder="Nombre"
                        label="nombre"
                        :options="listasForms.productos"
                        @input="seleccionarProducto()"
                      ></v-select>
                    </div> -->
                          <div class="form-group col-md-4">
                            <label>Transportadora</label>
                            <v-select
                              :class="
                                $store.getters.getDarkMode ? 'dark-vselect' : ''
                              "
                              v-model="transportadora"
                              placeholder="Razon social"
                              label="razon_social"
                              :options="listasForms.empresas"
                              @input="seleccionarTransportadora()"
                            ></v-select>
                          </div>
                          <div class="form-group col-md-2">
                            <label>Sitio cargue</label>
                            <v-select
                              :class="
                                $store.getters.getDarkMode ? 'dark-vselect' : ''
                              "
                              v-model="sitio_cargue"
                              placeholder="Sitio cargue"
                              label="nombre"
                              :options="listasForms.sitios"
                              @input="seleccionarSitioCargue()"
                            ></v-select>
                          </div>
                          <div class="form-group col-md-2">
                            <label>Sitio descargue</label>
                            <v-select
                              :class="
                                $store.getters.getDarkMode ? 'dark-vselect' : ''
                              "
                              v-model="sitio_descargue"
                              placeholder="Sitio descargue"
                              label="nombre"
                              :options="listasForms.sitios"
                              @input="seleccionarSitioDescargue()"
                            ></v-select>
                          </div>
                          <div class="form-group col-md-2">
                            <label>Ruta</label>
                            <v-select
                              :class="
                                $store.getters.getDarkMode ? 'dark-vselect' : ''
                              "
                              v-model="ruta"
                              placeholder="Nombre"
                              label="nombre"
                              :options="listasForms.rutas"
                              @input="seleccionarRuta()"
                            ></v-select>
                          </div>
                          <div class="form-group col-md-2">
                            <label>Estado</label>
                            <select
                              class="form-control form-control-sm"
                              v-model="filtros.estado"
                              @change="getIndex()"
                            >
                              <option value="">Seleccione...</option>
                              <option
                                v-for="estado in listasForms.estados"
                                :key="estado.numeracion"
                                :value="estado.numeracion"
                              >
                                {{ estado.descripcion }}
                              </option>
                            </select>
                          </div>
                          <div class="form-group col-md-4">
                            <label>Rango de fechas de descargue</label>
                            <div class="row">
                              <div class="col-md-6">
                                <input
                                  type="date"
                                  class="form-control form-control-sm"
                                  v-model="filtros.fecha_inicio"
                                  @input="validaFechas()"
                                />
                              </div>
                              <div class="col-md-6">
                                <input
                                  type="date"
                                  class="form-control form-control-sm"
                                  v-model="filtros.fecha_fin"
                                  @input="validaFechas()"
                                />
                              </div>
                            </div>
                          </div>
                          <div class="col-md-2">
                            <div class="form-group">
                              <label for="fecha_expedicion"
                                >Fecha Expedición</label
                              >
                              <input
                                type="date"
                                class="form-control form-control-sm"
                                v-model="filtros.fecha_expedicion"
                              />
                            </div>
                          </div>
                          <div class="form-group col-md-2">
                            <label>Areas Pendientes</label>
                            <select
                              class="form-control form-control-sm"
                              v-model="filtros.area"
                              @change="getIndex()"
                            >
                              <option value="">Seleccione...</option>
                              <option
                                v-for="area in listasForms.areas"
                                :key="area.numeracion"
                                :value="area.numeracion"
                              >
                                {{ area.descripcion }}
                              </option>
                            </select>
                          </div>
                          <div class="col-md-1">
                            <div class="btn-group float-right">
                              <button
                                type="button"
                                style="
                                cursor: pointer;
                                background: linear-gradient(to right, #1e3a8a, #3b82f6);
                                color: white;
                                border: none;
                                padding: 4px 10px;
                                border-radius: 5px;
                                "
                                class="btn  mt-3"
                                @click="getIndex()"
                              >
                                <i class="fas fa-search"></i><br />Buscar
                              </button>
                            </div>
                          </div>
                          <div class="col-md-1">
                            <div class="btn-group float-right">
                              <button
                                type="button"
                                style="
                                cursor: pointer;
                                background: linear-gradient(to right, #b91c1c, #ef4120);
                                color: white;
                                border: none;
                                padding: 4px 10px;
                                border-radius: 5px;
                                "
                                class="btn mt-3"
                                @click="limpiar()"
                              >
                                <i class="fas fa-broom"></i><br />Limpiar
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12">
                      <table
                        style="font-size: 9px; overflow-x: auto"
                        id="faltantes"
                        class="
                            table
                            table-bordered
                            table-striped
                            table-hover
                            table-sm
                            team-roster-table
                            table-responsive
                          "
                      >
                        <thead class="thead">
                          <tr>
                            <th>#</th>
                            <th>Guía</th>
                            <th>Vehículo</th>
                            <th>Remolque</th>
                            <th>Conductor</th>
                            <th>Transportadora</th>
                            <th>Ruta</th>
                            <th>Producto</th>
                            <th>Sitio cargue</th>
                            <th>Sitio descargue</th>
                            <th>Fecha de Cargue</th>
                            <th>Fecha de Descargue</th>
                            <th>NSV Cargue</th>
                            <th>NSV Descargue</th>
                            <th>NSV Diferencia</th>
                            <th>Porcentaje Diferencia</th>
                            <th>Numero de Replica</th>
                            <th>Estado</th>
                            <th>Areas</th>
                            <th
                              v-show="
                                $store.getters.can(
                                  'hidrocarburos.faltantes.asignarArea'
                                )
                              "
                            >
                              Autorizar envío
                            </th>
                            <th
                              v-show="
                                $store.getters.can(
                                  'hidrocarburos.faltantes.justificacionMediciones'
                                )
                              "
                            >
                              Loteo
                            </th>
                            <th
                              v-show="
                                $store.getters.can(
                                  'hidrocarburos.faltantes.justificacionMediciones'
                                )
                              "
                            >
                              Round
                            </th>
                            <th
                              v-show="
                                $store.getters.can(
                                  'hidrocarburos.faltantes.ingresoFactura'
                                )
                              "
                            >
                              N° Factura
                            </th>
                            <th>Acciones</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="item in faltantes.data" :key="item.id">
                            <td>{{ item.id }}</td>
                            <td
                              v-if="
                                item.viaje !== null && item.viaje.guia != null
                              "
                            >
                              {{ item.viaje.guia.numero }}-{{
                                item.viaje.guia.digito_verificacion
                              }}
                            </td>
                            <td v-else class="text-center">
                              <span class="badge badge-danger"
                                >Sin asignar</span
                              >
                            </td>
                            <td
                              v-if="
                                item.viaje !== null &&
                                  item.viaje.vehiculo != null
                              "
                            >
                              <div>
                                {{ item.viaje.vehiculo.placa }}
                              </div>
                            </td>
                            <td v-else class="text-center">
                              <span class="badge badge-danger"
                                >Sin asignar</span
                              >
                            </td>
                            <td
                              v-if="
                                item.viaje !== null &&
                                  item.viaje.remolque != null
                              "
                            >
                              {{ item.viaje.remolque.placa }}
                            </td>
                            <td v-else class="text-center">
                              <span class="badge badge-danger"
                                >Sin asignar</span
                              >
                            </td>
                            <td
                              v-if="
                                item.viaje !== null &&
                                  item.viaje.conductor != null
                              "
                            >
                              <div>
                                {{ item.viaje.conductor.nombres }}
                                {{ item.viaje.conductor.apellidos }}
                              </div>
                              <span class="badge badge-info">
                                {{ item.viaje.conductor.numero_documento }}
                              </span>
                            </td>
                            <td v-else class="text-center">
                              <span class="badge badge-danger"
                                >Sin asignar</span
                              >
                            </td>
                            <td
                              v-if="
                                item.viaje !== null &&
                                  item.viaje.transportadora != null
                              "
                            >
                              {{ item.viaje.transportadora.razon_social }}
                            </td>
                            <td v-else></td>
                            <td
                              v-if="
                                item.viaje !== null && item.viaje.ruta != null
                              "
                            >
                              {{ item.viaje.ruta.nombre }}
                            </td>
                            <td v-else></td>
                            <td
                              v-if="
                                item.viaje !== null &&
                                  item.viaje.producto_liquido != null
                              "
                            >
                              {{ item.viaje.producto_liquido.nombre }}
                            </td>
                            <td v-else></td>
                            <td
                              v-if="
                                item.viaje !== null &&
                                  item.viaje.sitio_cargue != null
                              "
                            >
                              {{ item.viaje.sitio_cargue.nombre }}
                            </td>
                            <td v-else></td>
                            <td
                              v-if="
                                item.viaje !== null &&
                                  item.viaje.sitio_descargue != null
                              "
                            >
                              {{ item.viaje.sitio_descargue.nombre }}
                            </td>

                            <td v-else></td>
                            <td class="text-center">
                              {{ item.viaje.guia.det_guias[0].fecha_cargue }}
                            </td>
                            <td class="text-center">
                              {{
                                item.viaje.guia.cierre_guias[0].fecha_hora_descargue.split(
                                  " "
                                )[0]
                              }}
                            </td>
                            <td class="text-center">{{ item.nsv_cargue }}</td>
                            <td class="text-center">
                              {{ item.nsv_descargue }}
                            </td>
                            <td class="text-center">
                              {{ item.nsv_diferencia }}
                            </td>
                            <td class="text-center">
                              {{ item.porcentaje_diferencia }}
                            </td>
                            <td class="text-center">
                              <strong>{{ item.cantidad_envios }}</strong>
                            </td>

                            <td class="text-center" v-if="item.viaje !== null">
                              <span
                                class="badge"
                                :class="getIconClass(item.estado)"
                              >
                                {{ item.nEstado }}
                              </span>
                            </td>
                            <td class="text-center" v-if="item.viaje !== null">
                              <div
                                v-for="areas in item.diferencias_guia_areas"
                                :key="areas.id"
                              >
                                <span class="badge" v-if="areas.estado == 1">
                                  {{ areas.Narea }}
                                </span>
                              </div>
                            </td>
                            <td
                              v-show="
                                $store.getters.can(
                                  'hidrocarburos.faltantes.asignarArea'
                                )
                              "
                              style="height: 100%; padding: 0; display: table-cell; vertical-align: middle;"
                            >
                              <div
                                v-if="
                                  (item.cantidad_envios >= 2 &&
                                    item.estado == 6) ||
                                    item.intento == 1
                                "
                                class="d-flex justify-content-center align-items-center"
                                style="height: 100%; font-size: 23px; width: 100%;"
                              >
                                <input
                                  type="checkbox"
                                  style="transform: scale(1.2);"
                                  :checked="item.intento === 1"
                                  :disabled="item.intento === 1"
                                  @change="guardarIntento(item)"
                                />
                                <!-- Escala del checkbox -->
                              </div>
                            </td>
                              <!-- loteo -->
                            <td
                              v-show="
                                $store.getters.can(
                                  'hidrocarburos.faltantes.justificacionMediciones'
                                )
                              "
                              style="height: 100%; padding: 0; display: table-cell; vertical-align: middle;"
                            >
                              <a
                                v-if="item.loteo == 1"
                                class="tooltip-container"
                                title="Cumple"
                                style="height: 100%; font-size: 13px; width: 100%;"
                              >
                                <i
                                  class="fa fa-check text-success d-flex justify-content-center align-items-center"
                                ></i>
                              </a>
                              <a v-else-if="item.loteo == 2">
                                <i
                                  class="fa fa-times-circle text-danger d-flex justify-content-center align-items-center"
                                  title="No Cumple"
                                  style="height: 100%; font-size: 13px; width: 100%;"
                                ></i>
                              </a>
                              <a v-else>
                                <i
                                  class="fa fa-exclamation-circle text-warning d-flex justify-content-center align-items-center"
                                  title="No calculado"
                                  style="height: 100%; font-size: 13px; width: 100%;"
                                ></i>
                              </a>
                            </td>


                            <td
                              v-show="
                                $store.getters.can(
                                  'hidrocarburos.faltantes.justificacionMediciones'
                                )
                              "
                              style="height: 100%; padding: 0; display: table-cell; vertical-align: middle;"
                            >
                              <a
                                v-if="item.round_trip == 1"
                                class="tooltip-container"
                                title="Cumple"
                                style="height: 100%; font-size: 13px; width: 100%;"
                              >
                                <i
                                  class="fa fa-check text-success d-flex justify-content-center align-items-center"
                                ></i>
                              </a>
                              <a v-else-if="item.round_trip ==2">
                                <i
                                  class="fa fa-times-circle text-danger d-flex justify-content-center align-items-center"
                                  title="No Cumple"
                                  style="height: 100%; font-size: 13px; width: 100%;"
                                ></i>
                              </a>
                              <a v-else>
                                <i
                                  class="fa fa-exclamation-circle text-warning d-flex justify-content-center align-items-center"
                                  title="No calculado"
                                  style="height: 100%; font-size: 13px; width: 100%;"
                                ></i>
                              </a>
                            </td>

                            <td
                              style="height: 100%; padding: 0; display: table-cell; vertical-align: middle; "
                              v-show="
                                $store.getters.can(
                                  'hidrocarburos.faltantes.ingresoFactura'
                                )
                              "
                            >
                              <a
                                v-if="item.n_factura"
                                class="tooltip-container"
                                :title="item.n_factura"
                                style="height: 100%; font-size: 13px; width: 100%;"
                              >
                                <i
                                  class="fa fa-check text-success d-flex justify-content-center align-items-center"
                                ></i>
                              </a>
                              <i
                                v-else-if="item.estado == 5"
                                class="fa fa-times-circle text-danger d-flex justify-content-center align-items-center"
                                title="No se realiza cobro"
                                style="height: 100%; font-size: 13px; width: 100%;"
                              ></i>

                              <a
                                v-else-if="item.estado == 6"
                                class="btn col-md-12 text-warning d-flex justify-content-center align-items-center"
                                data-toggle="modal"
                                data-target="#modal-form-factura"
                                style="cursor: pointer"
                                @click="modalFactura(item)"
                              >
                                <i class="fas fa-receipt"></i>
                              </a>

                              <i
                                v-else
                                class="fa fa-times-circle text-navy d-flex justify-content-center align-items-center"
                                title="Pendiente determinar cobro"
                                style="height: 100%; font-size: 13px; width: 100%;"
                              ></i>
                            </td>

                            <td>
                              <a
                                class="btn col-md-12"
                                :class="getIconClass(item.estado)"
                                data-toggle="modal"
                                data-target="#modal-escaner-documento"
                                style="cursor: pointer"
                                @click="modalDocumento(item)"
                              >
                                <i class="fas fa-file-pdf"></i>
                              </a>
                              <!-- <a class="btn col-md-12">
                                  <i class="fas fa-file-invoice-dollar"></i>
                                </a> -->
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>

                <div class="card-footer">
                  <div class="float-left" v-if="faltantes.total">
                    <p>
                      Mostrando del <b>{{ faltantes.from }}</b> al
                      <b>{{ faltantes.to }}</b> de un total:
                      <b>{{ faltantes.total }}</b> Registros
                    </p>
                  </div>
                  <div class="float-left" v-else>
                    <p>
                      <span class="badge badge-danger">
                        No hay registros para mostrar
                      </span>
                    </p>
                  </div>
                  <pagination
                    :data="faltantes"
                    @pagination-change-page="getIndex"
                    :limit="5"
                    class="text-right"
                  >
                  </pagination>
                </div>
              </div>
            </div>
          </div>
        </section>
        <FaltantesEscanerDocumento ref="FaltantesEscanerDocumento" />
        <FaltantesFactura ref="FaltantesFactura" />
        <FaltantesRoundTrip ref="FaltantesRoundTrip" />
      </div>
    </div>
    <FaltantesExport ref="FaltantesExport" />
  </div>
</template>
<script>
import pagination from "laravel-vue-pagination";
import FaltantesEscanerDocumento from "./FaltantesEscanerDocumento";
import FaltantesFactura from "./FaltantesFactura";
import FaltantesRoundTrip from "./FaltantesRoundTrip";
import vSelect from "vue-select";
import axios from "axios";
import Loading from "../../../../components/Loading";
import FaltantesExport from "./FaltantesExport.vue";
export default {
  name: "FaltantesIndex",
  components: {
    Loading,
    pagination,
    FaltantesEscanerDocumento,
    vSelect,
    FaltantesRoundTrip,
    FaltantesFactura,
    FaltantesExport,
  },
  data() {
    return {
      invoiceNumber: "",
      cargando: false,
      faltantes: {},
      filtros: {},
      conductor: null,
      vehiculo: null,
      tipo_combustible: null,
      remolque: null,
      transportadora: null,
      ruta: null,
      producto: null,
      ver_filtros: false,
      sitio_cargue: null,
      sitio_descargue: null,
      ultimo_punto: null,
      aceptacion: null,
      formAceptacion: {
        aceptacion: null,
        viaje_id: null,
      },
      listasForms: {
        estados: [],
        sitios: [],
        conductores: [],
        productos: [],
        rutas: [],
        cabezotes: [],
        tiposCombustible: [],
        remolques: [],
        empresas: [],
        puntos_gestion: [],
        sitio_types: [],
        aceptacion: [],
        areas: [],
      },
      uri_docs: process.env.VUE_APP_AWS_BUCKET_URL,
    };
  },
  methods: {
    getIndex(page = 1) {
      this.cargando = true;
      axios
        .get("/api/hidrocarburos/faltantes?page=" + page, {
          params: this.filtros,
        })
        .then((response) => {
          this.cargando = false;
          this.faltantes = response.data;
        });
    },

    getIconClass(estado) {
      switch (estado) {
        case 2:
          if (
            this.$store.getters.can("hidrocarburos.faltantes.subirDocuemtos")
          ) {
            return "alert-default-warning border border-warning";
          } else {
            return "alert-default-success border border-success";
          }
        case 3:
          if (this.$store.getters.can("hidrocarburos.faltantes.asignarArea")) {
            return "alert-default-warning border border-warning";
          } else {
            return "alert-default-success border border-success";
          }

        case 4:
          if (
            this.$store.getters.can(
              "hidrocarburos.faltantes.justificacionTransporte"
            ) ||
            this.$store.getters.can(
              "hidrocarburos.faltantes.justificacionMediciones"
            ) ||
            this.$store.getters.can(
              "hidrocarburos.faltantes.justificacionSeguridadFisica"
            )
          ) {
            return "alert-default-warning border border-warning";
          } else {
            return "alert-default-success border border-success";
          }
        case 5:
          return "alert-default-danger border border-danger";
        case 6:
          return "alert-default-primary border border-primary";
        case 7:
          if (this.$store.getters.can("hidrocarburos.faltantes.confirmador")) {
            return "alert-default-warning border border-warning";
          } else {
            return "alert-default-success border border-success";
          }
        case 8:
          return "alert-default-primary border border-primary";
        case 9:
          if (
            this.$store.getters.can("hidrocarburos.faltantes.adminContratos")
          ) {
            return "alert-default-warning border border-warning";
          } else {
            return "alert-default-success border border-success";
          }
      }
    },
    async guardarIntento(item) {
      if (item.intento !== 1) {
        // Si el estado no es 1
        const response = await axios.put(
          "/api/hidrocarburos/faltantes/guardar_estado/" + item.id,
          { intento: 1 }
        );
        // Actualizar el estado en el objeto item
        item.intento = 1;

        // Mostrar mensaje de éxito
        this.$swal({
          icon: "success",
          title:
            "Se asignó un tercer intento a la guía: " +
            item.viaje.guia.numero +
            "-" +
            item.viaje.guia.digito_verificacion,
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
        this.getIndex();
      }
    },
    getUserEmpresa() {
      this.filtros.userEmpresa = [];
      for (const iterator of this.$store.getters.getUser.empresas) {
        this.filtros.userEmpresa.push(iterator.id);
      }
    },

    validaFechas() {
      const fecha_menor = new Date(this.filtros.fecha_inicio);
      const fecha_mayor = new Date(this.filtros.fecha_fin);
      // Se valida que la fecha inicial sea menor que la fecha final
      if (fecha_menor > fecha_mayor) {
        this.filtros.fecha_fin = null;
        this.$swal({
          icon: "error",
          title: `La fecha inicio no puede ser mayor a la fecha fin...`,
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
      }
    },

    limpiar() {
      this.filtros.id = "";
      this.filtros.guia_id = "";
      this.filtros.ticket_id = "";
      this.filtros.vehiculo_id = "";
      this.filtros.remolque_id = "";
      this.filtros.conductor_id = "";
      this.filtros.producto_id = "";
      this.filtros.transportadora_id = "";
      this.filtros.sitio_cargue_id = "";
      this.filtros.sitio_descargue_id = "";
      this.filtros.ruta_id = "";
      this.filtros.estado = "";
      this.filtros.sitio_type = "";
      this.filtros.fecha_inicio = "";
      this.filtros.fecha_fin = "";
      this.filtros.fecha_expedicion = "";
      this.filtros.aceptacion = "";
      this.conductor = null;
      this.vehiculo = null;
      this.remolque = null;
      this.transportadora = null;
      this.ruta = null;
      this.producto = null;
      this.sitio_cargue = null;
      this.sitio_descargue = null;
      this.ultimo_punto = null;
      this.getIndex();
    },

    getSitioType() {
      // sitio 1, punto gestion 2
      axios.get("/api/lista/25").then((response) => {
        this.listasForms.sitio_types = response.data;
      });
    },

    buscarCabezotes() {
      let me = this;
      var url = "api/admin/vehiculos/lista?estado=1";
      axios
        .get(url)
        .then(function(response) {
          me.listasForms.cabezotes = response.data;
        })
        .catch(function(error) {
          this.$swal({
            icon: "error",
            title: "Ocurrió un error: " + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    getTiposCombustibles() {
      axios.get("/api/lista/24").then((response) => {
        this.listasForms.tiposCombustible = response.data;
      });
    },

    buscarRemolques() {
      let me = this;
      var url = "api/admin/remolques/lista?estado=1";
      axios
        .get(url)
        .then(function(response) {
          me.listasForms.remolques = response.data;
        })
        .catch(function(error) {
          this.$swal({
            icon: "error",
            title: "Ocurrió un error: " + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    buscarRutas() {
      let me = this;
      var url = "api/admin/rutas/lista";
      axios
        .get(url)
        .then(function(response) {
          me.listasForms.rutas = response.data;
        })
        .catch(function(error) {
          this.$swal({
            icon: "error",
            title: "Ocurrió un error: " + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    buscarConductores() {
      let me = this;
      var url = "api/admin/conductores/lista?estado=1";
      axios
        .get(url)
        .then(function(response) {
          me.listasForms.conductores = response.data;
        })
        .catch(function(error) {
          this.$swal({
            icon: "error",
            title: "Ocurrió un error: " + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    buscarTransportadora() {
      let me = this;
      var url = "api/admin/empresas/lista?estado=1";
      axios
        .get(url)
        .then(function(response) {
          me.listasForms.empresas = response.data;
        })
        .catch(function(error) {
          this.$swal({
            icon: "error",
            title: "Ocurrió un error: " + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    buscarSitios() {
      let me = this;
      var url = "api/admin/sitios/lista";
      axios
        .get(url, {
          params: {
            estado: 1,
          },
        })
        .then(function(response) {
          let respuesta = response.data;
          me.listasForms.sitios = respuesta;
        })
        .catch(function(error) {
          me.$swal({
            icon: "error",
            title: "Ocurrió un error: " + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    buscarPuntosGestion() {
      let me = this;
      var url = "api/admin/puntos_gestion/lista";
      axios
        .get(url, {
          params: {
            estado: 1,
          },
        })
        .then(function(response) {
          let respuesta = response.data;
          me.listasForms.puntos_gestion = respuesta;
        })
        .catch(function(error) {
          me.$swal({
            icon: "error",
            title: "Ocurrió un error: " + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    seleccionarVehiculo() {
      if (this.vehiculo != null) {
        this.filtros.vehiculo_id = this.vehiculo.id;
      } else {
        this.filtros.vehiculo_id = null;
      }
    },

    seleccionarTipoCombustible() {
      this.filtros.tipo_combustible = this.tipo_combustible
        ? this.tipo_combustible.numeracion
        : null;
    },

    seleccionarRemolque() {
      if (this.remolque != null) {
        this.filtros.remolque_id = this.remolque.id;
      } else {
        this.filtros.remolque_id = null;
      }
    },

    seleccionarTransportadora() {
      if (this.transportadora != null) {
        this.filtros.transportadora_id = this.transportadora.id;
      } else {
        this.filtros.transportadora_id = null;
      }
    },

    seleccionarConductor() {
      if (this.conductor != null) {
        this.filtros.conductor_id = this.conductor.id;
      } else {
        this.filtros.conductor_id = null;
      }
    },

    seleccionarRuta() {
      if (this.ruta != null) {
        this.filtros.ruta_id = this.ruta.id;
      } else {
        this.filtros.ruta_id = null;
      }
    },

    seleccionarProducto() {
      if (this.producto != null) {
        this.filtros.producto_id = this.producto.id;
      } else {
        this.filtros.producto_id = null;
      }
    },

    seleccionarSitioCargue() {
      if (this.sitio_cargue != null) {
        this.filtros.sitio_cargue_id = this.sitio_cargue.id;
      } else {
        this.filtros.sitio_cargue_id = null;
      }
    },

    seleccionarSitioDescargue() {
      if (this.sitio_descargue != null) {
        this.filtros.sitio_descargue_id = this.sitio_descargue.id;
      } else {
        this.filtros.sitio_descargue_id = null;
      }
    },

    seleccionarSitio() {
      if (this.filtros.sitio_type == 1 || this.filtros.sitio_type == 2) {
        if (this.ultimo_punto != null) {
          this.filtros.sitio_id = this.ultimo_punto.id;
        } else {
          this.filtros.sitio_id = null;
        }
      }
    },
    limpiarModal() {
      this.aceptacion = null;
      this.formAceptacion = {
        aceptacion: null,
        viaje_id: null,
      };
    },

    getEstados() {
      axios.get("/api/lista/201").then((response) => {
        this.listasForms.estados = response.data;
      });
    },

    getAreas() {
      axios.get("/api/lista/207").then((response) => {
        this.listasForms.areas = response.data;
      });
    },

    getFuncionarios() {
      axios.get("/api/admin/funcionarios/list").then((response) => {
        this.listasForms.funcionarios = response.data.filter(
          (funcionario) => funcionario.admin_contrato === 1
        );
      });
    },

    modalDocumento(faltante) {
      this.$refs.FaltantesEscanerDocumento.llenar_modal_documento(faltante);
    },
    modalFactura(faltante) {
      this.$refs.FaltantesFactura.llenar_modal(faltante);
    },
    modalRoundTrip() {
      this.$refs.FaltantesRoundTrip.getIndex();
    },
    saveInvoice() {
      // Aquí puedes agregar la lógica para guardar el número de factura
      // Por ejemplo, llamar a una API o actualizar un objeto local
      console.log("Número de factura guardado:", this.invoiceNumber);
      this.closeModal();
    },

    exportData() {
      //this.$parent.cargando = true;
      axios({
        method: "get",
        url: "/api/hidrocarburos/faltantes/export",
      })
        .then((response) => {
          let data = response.data;
          //this.$parent.cargando = false;
          location.href = data.url;
        })
        .catch((e) => {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error inesperado " + e,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 4000,
            timerProgressBar: true,
          });
          //this.$parent.cargando = false;
        });
    },
  },

  mounted() {
    this.getIndex();
    this.getEstados();
    this.buscarCabezotes();
    this.getTiposCombustibles();
    this.buscarRemolques();
    this.buscarTransportadora();
    this.buscarConductores();
    this.buscarRutas();
    this.getSitioType();
    this.buscarSitios();
    this.buscarPuntosGestion();
    this.getFuncionarios();
    this.getAreas();
  },
};
</script>
